<template>
  <form-dialog
    :title="`تثبيت الحجز`"
    ref="form"
    :dialog.sync="dialog"
    :load="loadDialog"
    @close="$emit('close')"
    :readonly="readonly"
    @submit="$emit('submit')"
    width="400px"
  >
    <template v-slot:content>
      <v-row>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            :label="$t('attributes.password') + '*'"
            v-model="form.password"
            required
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        
      </v-row>
    </template>
  </form-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormDialog from "../../../components/customs/form-dialog.vue";
export default {
  components: { FormDialog },
  props: {
    dialog: Boolean,
    readonly: Boolean,
    edit: Boolean,
    loadDialog: Boolean,
  },
  mounted() {},
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters("Reservations", [
      "account",
      "form",
      "classrooms",
      "accounts",
    ]),

  },
  methods: {
    submit(flag) {
      this.filter({
        type: this.form.reservation_type,
        date: this.form.date,
        time: this.form.time,
      })
        .then((res) => {
          console.log(res);
          if (flag == 0) {
            this.$refs.dialog.save(this.form.date);
          } else if (flag == 1) {
            this.$refs.dialog1.save(this.form.time);
          }
        })
        .catch((err) => {
          const { message } = err.response.data;
          this.send_error(message);
        });
    },
    ...mapActions("Reservations", ["create", "filter"]),
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (this.dialog) {
        this.form.password = null;
      }
    },
  },
};
</script>

<style>
</style>